<template>
  <v-dialog
    v-model="visible"
    max-width="800"
    scrim="transparent"
  >
    <v-card
      border
      flat
      tile
    >
      <v-card-text class="mnh-250 mt-4">
        <template v-if="loaded">
          <QuestionSet
            v-model="claim"
            @back="backFromQuestion($event)"
            @change:attachments="loadAttachments()"
            @next="forwardFromQuestion($event)"
            :attachment-group-id="familySubsidy?.group_id"
            :attachment-owner-id="claim.id"
            :attachment-owner-type="'Claim'"
            :attachments="claimAttachments"
            :processing="processing"
            :questions="validQuestions"
            :schema="schema.definition"
            :section="section"
            :transition-name="transitionName"
            key-name="question"
          />
        </template>

        <template v-else>
          <v-progress-linear
            class="my-8"
            indeterminate
          />
        </template>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script setup>
import Api from '@/shared/services/all_bright_finder';
import QuestionSet from '@/shared/components/form/QuestionSet.vue';
import useEventBus from '@/shared/composables/useEventBus';
import useQuestionable from '@/shared/composables/useQuestionable';
import { useRoute, useRouter } from 'vue-router';
import useRouterHelper from '@/shared/composables/useRouterHelper';
import useStepper from '@/shared/composables/useStepper';
import { useStore } from 'vuex';

const props = defineProps({
  familySubsidy: {
    type: Object,
    default: null,
  },
  grantId: {
    type: String,
    default: null,
  },
  schemaId: {
    type: String,
    default: null,
  },
  title: {
    type: String,
    default: null,
  },
});

defineExpose({ open });
const emit = defineEmits(['save']);

const eventBus = useEventBus();
const route = useRoute();
const router = useRouter();
const store = useStore();
const { updateQuery } = useRouterHelper();

const claim = ref(null);
const claimAttachments = ref([]);
const processing = ref(false);
const schema = ref(null);
const visible = ref(false);

const claimSchemaId = computed(() => props.schemaId || claim.value?.schema_id);

const { questionsLoaded, validateAnswers, validQuestions } = useQuestionable({
  ownerDataTypeRef: ref('Schema'),
  ownerIdRef: claimSchemaId,
  syncedObjectRef: claim,
});

const { section, stepBack, stepForward, transitionName } = useStepper({
  processing,
  route,
  updateQuery,
});

const loaded = computed(() => {
  return claim.value && schema.value && questionsLoaded.value;
});

const claimRepository = computed(() => {
  if (store.state.role === 'parent') return Api.parent.claim;
  if (store.state.role === 'manager') return Api.manager.claim;
  return null;
});

const attachmentRepository = computed(() => {
  if (store.state.role === 'parent') return Api.member.attachment;
  if (store.state.role === 'manager') return Api.manager.attachment;
  return null;
});

async function backFromQuestion(currentIndex) {
  processing.value = true;
  await save();

  if (currentIndex - 1 < 0) {
    processing.value = false;
    close();
  } else {
    stepBack(`question-${currentIndex - 1}`);
  }
}

async function forwardFromQuestion(currentIndex) {
  processing.value = true;
  await save();

  await validateAnswers();
  if (currentIndex + 1 >= validQuestions.value.length) {
    void submitClaim();
  } else {
    stepForward(`question-${currentIndex + 1}`);
  }
}

function close() {
  visible.value = false;
  section.value = null;
  claim.value = null;
  schema.value = null;

  const queryParams = { ...route.query };
  delete queryParams.section;
  delete queryParams.step;
  router.push({ query: queryParams });
}

async function loadAttachments() {
  processing.value = true;
  const resp = await attachmentRepository.value.index({
    owner_id: claim.value.id,
    owner_type: 'Claim',
  });
  if (resp?.status === 200) {
    claimAttachments.value = resp.data;
  }
  processing.value = false;
  return true;
}

async function open(claimId) {
  visible.value = true;
  processing.value = true;
  const queryParams = { ...route.query };
  queryParams.section = 'question-0';
  queryParams.step = 1;
  router.push({ query: queryParams });

  await setClaim(claimId);
  await loadAttachments();

  section.value = 'question-0';
  processing.value = false;
}

async function save() {
  await claimRepository.value.update(claim.value.id, claim.value);
  emit('save');
}

async function setClaim(claimId) {
  let resp;
  if (claimId) {
    resp = await claimRepository.value.get(claimId);
    if (resp?.status !== 200) return;
  } else {
    const params = {
      custom: {},
      family_subsidy_id: props.familySubsidy?.id,
      grant_id: props.grantId,
      schema_id: props.schemaId,
    };
    resp = await claimRepository.value.create(params);
    if (resp?.status !== 201) return;
  }
  claim.value = resp.data;

  schema.value = store.state.schemas[claim.value.schema_id || props.schemaId];
}

async function submitClaim() {
  const resp = await claimRepository.value.submit(claim.value.id);
  if (resp?.status !== 200) {
    processing.value = false;
  } else {
    emit('save');
    processing.value = false;
    eventBus.chime('Claim submitted.');
    close();
  }
}
</script>
